import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoginCallbackLayout from '../layouts/LoginCallbackLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              backgroundColor: '#9f9f9f54',
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { role } = useAuth();
  // console.log('role', role);
  return useRoutes([
    // Dashboard Routes
    {
      path: '',
      element: (
        <DashboardLayout>
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        </DashboardLayout>
      ),
      children: [
        {
          path: '/',
          element:
            role && role === 'C' ? (
              <Navigate to="/interface/po" replace />
            ) : (
              <Navigate to="/new-regist/status-tracking" replace />
            )
        },
        {
          path: 'report-plantation-cost',
          children: [
            { path: '/', element: <Navigate to="/report-plantation-cost/by-activity" replace /> },
            { path: 'by-activity', element: <PlantationCostByActivity /> },
            { path: 'by-assignment', element: <PlantationCostByAssignment /> }
          ]
        },
        { path: 'report-benefit-cost', element: <BenefitCost /> },
        { path: 'report-fair-value', element: <FairValue /> },

        { path: '/bi', element: <Dashboard /> },
        { path: '/force-change-assword', element: <ForceChangePassword /> },
        {
          path: 'master',
          // element: (
          //   <RoleBasedGuard accessibleRoles={['Credit']}>
          //     <Outlet />
          //   </RoleBasedGuard>
          // ),
          children: [
            { path: '/', element: <Navigate to="/master/activity" replace /> },
            { path: 'activity', element: <Activity /> },
            { path: 'clone', element: <Clone /> },
            { path: 'transportation', element: <Transportation /> },
            { path: 'user', element: <User /> }
          ]
        },
        {
          path: 'new-regist',
          // element: (
          //   <RoleBasedGuard>
          //     <Outlet />
          //   </RoleBasedGuard>
          // ),
          children: [
            { path: '/', element: <Navigate to="/new-regist/status-tracking" replace /> },
            { path: 'status-tracking/:registNo', element: <StatusTracking /> },
            { path: 'status-tracking', element: <StatusTracking /> },
            { path: 'power-app', element: <PowerApp /> },
            { path: 'rental/:id', element: <Rental /> },
            { path: 'subrental/:id', element: <SubRental /> },
            { path: 'subrentalphysical/:id', element: <SubRentalPhysical /> },
            { path: 'mou/:id', element: <Mou /> },
            { path: 'vip/:id', element: <Vip /> },
            { path: 'assumptionandexpense/:id', element: <AssumptionAndExpensePage /> },
            { path: 'assumptionandexpense', element: <AssumptionAndExpensePage /> },
            { path: 'subvip/:id', element: <SubVip /> }
          ]
        },
        {
          path: 'plantation',
          // element: (
          //   <RoleBasedGuard>
          //     <Outlet />
          //   </RoleBasedGuard>
          // ),
          children: [
            { path: '/', element: <Navigate to="/plantation/create-plantation" replace /> },
            { path: 'create-plantation/:plantationNo', element: <CreatePlantation /> },
            { path: 'create-plantation', element: <CreatePlantation /> },
            { path: 'create-unplan', element: <CreateUnplan /> },
            { path: 'rolling-plan', element: <RollingPlan /> },
            { path: 'rolling-plan-detail/:id', element: <RollingPlanDetail /> },
            { path: 'calendar', element: <Calendar /> },
            { path: 'plantationrental/:id', element: <PlantationRental /> },
            { path: 'plantationsubrental/:id', element: <PlantationSubRental /> },
            { path: 'plantationvip/:id', element: <PlantationVip /> },
            { path: 'plantationsubvip/:id', element: <PlantationSubVip /> },
            { path: 'plantationmou/:id', element: <PlantationMou /> },
            { path: 'plantationsubmou/:id', element: <PlantationSubMou /> },
            { path: 'unplan/:id', element: <UnplanDetail /> },
            { path: 'unplan', element: <UnplanDetail /> },
            { path: 'masterplan/:id', element: <MasterPlan /> },
            { path: 'timeline/:type/:id', element: <TimeLine /> }
          ]
        },
        {
          path: 'interface',
          // element: (
          //   <RoleBasedGuard>
          //     <Outlet />
          //   </RoleBasedGuard>
          // ),
          children: [
            { path: '/', element: <Navigate to="/interface/po" replace /> },
            { path: 'po', element: <PO /> },
            { path: 'po/:transactionNo', element: <PO /> },
            { path: 'create-po', element: <CreatePO /> },
            { path: 'create-po/:id', element: <CreatePO /> },
            { path: 'create-po/:id/:action', element: <CreatePO /> },
            { path: 'create-non-po', element: <CreateNonPO /> },
            { path: 'non-po', element: <NonPO /> },
            { path: 'non-po/:id', element: <NonPO /> },

            { path: 'sap-gi', element: <SAPGI /> },
            { path: 'sap-gi/:transactionNo', element: <SAPGI /> },
            { path: 'create-sap-gi', element: <CreateSAPGI /> },
            { path: 'create-sap-gi/:id', element: <CreateSAPGI /> },
            { path: 'create-sap-gi/:id/:action', element: <CreateSAPGI /> },
            { path: 'mapping-99999', element: <Mapping9999 /> },
            { path: 'mapping-99999/:id', element: <MappingTrans /> },
            { path: 'payment/:id', element: <Payment /> },
            { path: 'payment/:id/:paymentId', element: <Payment /> }
          ]
        }
      ]
    },

    // Login Callback Routes
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <LoginCallbackLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'login-cb', element: <LoginRedirectCallback /> },
        { path: 'login-silent-cb', element: <LoginSilentCallback /> },
        { path: 'login-popup-cb', element: <LoginPopupCallback /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Logout
    { path: 'logout-backchannel', element: <LogoutBackChannel /> },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <DashboardLayout>
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        </DashboardLayout>
      ),
      children: [
        {
          path: '/',
          element:
            role && role === 'C' ? (
              <Navigate to="/interface/po" replace />
            ) : (
              <Navigate to="/new-regist/status-tracking" replace />
            )
        }
      ]
    }
  ]);
}

// IMPORT COMPONENTS
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Dashboard = Loadable(lazy(() => import('../pages/General/Dashboard')));

// Master
const Activity = Loadable(lazy(() => import('../pages/Master/MasterActivity')));
const Clone = Loadable(lazy(() => import('../pages/Master/MasterClone')));
const Transportation = Loadable(lazy(() => import('../pages/Master/MasterTransportation')));
const User = Loadable(lazy(() => import('../pages/Master/MasterUser')));

const PowerApp = Loadable(lazy(() => import('../pages/Management/NewRegist/PowerApp')));
const StatusTracking = Loadable(lazy(() => import('../pages/Management/StatusTracking')));
const SamplePage = Loadable(lazy(() => import('../pages/Management/SamplePage')));
const Rental = Loadable(lazy(() => import('../pages/Management/Rental')));
const SubRental = Loadable(lazy(() => import('../pages/Management/SubRental')));
const SubRentalPhysical = Loadable(lazy(() => import('../pages/Management/SubRentalPhysical')));
const Vip = Loadable(lazy(() => import('../pages/Management/Vip')));
const SubVip = Loadable(lazy(() => import('../pages/Management/SubVip')));
const Mou = Loadable(lazy(() => import('../pages/Management/Mou')));
const AssumptionAndExpensePage = Loadable(lazy(() => import('../pages/Management/AssumptionAndExpensePage')));
const MasterPlan = Loadable(lazy(() => import('../pages/Management/Plantation/MasterPlan')));

const CreatePlantation = Loadable(lazy(() => import('../pages/Management/Plantation/CreatePlantation')));
const PlantationRental = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationRental')));
const PlantationSubRental = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationSubRental')));
const PlantationVip = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationVip')));
const PlantationSubVip = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationSubVip')));
const PlantationMou = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationMou')));
const PlantationSubMou = Loadable(lazy(() => import('../pages/Management/Plantation/PlantationSubMou')));
const CreateUnplan = Loadable(lazy(() => import('../pages/Plantation/CreateUnplan')));
const RollingPlan = Loadable(lazy(() => import('../pages/Plantation/RollingPlan')));
const RollingPlanDetail = Loadable(lazy(() => import('../pages/Plantation/RollingPlanDetail')));
const UnplanDetail = Loadable(lazy(() => import('../pages/Plantation/CreateUnplanDetail')));
const Calendar = Loadable(lazy(() => import('../pages/Management/Calendar')));
const TimeLine = Loadable(lazy(() => import('../pages/Management/TimeLine')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// Login Callback
const LoginRedirectCallback = Loadable(lazy(() => import('../pages/auth/LoginRedirectCallback')));
const LoginPopupCallback = Loadable(lazy(() => import('../pages/auth/LoginPopupCallback')));
const LoginSilentCallback = Loadable(lazy(() => import('../pages/auth/LoginSilentCallback')));
const LogoutBackChannel = Loadable(lazy(() => import('../pages/auth/LogoutFrontChannel')));
const ForceChangePassword = Loadable(lazy(() => import('../pages/auth/ForceChangePassword')));

// Interface
const Mapping9999 = Loadable(lazy(() => import('../pages/Management/Interface/Mapping9999')));
const MappingTrans = Loadable(lazy(() => import('../pages/Management/Interface/MappingTrans')));
const Payment = Loadable(lazy(() => import('../pages/Management/Interface/Payment')));

const CreateNonPO = Loadable(lazy(() => import('../pages/Management/Interface/CreateNonPO')));
const NonPO = Loadable(lazy(() => import('../pages/Management/Interface/NonPO')));

const CreatePO = Loadable(lazy(() => import('../pages/Management/Interface/CreatePO')));
const PO = Loadable(lazy(() => import('../pages/Management/Interface/PO')));

const CreateSAPGI = Loadable(lazy(() => import('../pages/Management/Interface/CreateSAPGI')));
const SAPGI = Loadable(lazy(() => import('../pages/Management/Interface/SAPGI')));

// Report
const PlantationCostByActivity = Loadable(lazy(() => import('../pages/Report/PlantationCostByActivity')));
const PlantationCostByAssignment = Loadable(lazy(() => import('../pages/Report/PlantationCostByAssignment')));
const BenefitCost = Loadable(lazy(() => import('../pages/Report/BenefitCost')));
const FairValue = Loadable(lazy(() => import('../pages/Report/FairValue')));
