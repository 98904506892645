import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <g>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m100.640625 154.066406c4.125-24.464844-4.8125-46.371094-19.957031-48.921875-15.144532-2.550781-30.765625 15.210938-34.890625 39.675781-4.121094 24.464844 4.8125 46.367188 19.957031 48.921876 15.148438 2.550781 30.769531-15.210938 34.890625-39.675782zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m117.40625 489.308594c-73.46875-64.566406-67.488281-166.027344-67.21875-170.292969.667969-10.363281 9.605469-18.226563 19.964844-17.5625 10.359375.667969 18.207031 9.578125 17.566406 19.925781-.0625 1.082032-4.382812 87.277344 54.683594 139.839844 7.757812 6.902344 8.449218 18.785156 1.546875 26.542969-4.464844 5.019531-15.34375 11.390625-26.542969 1.546875zm0 0"
            fill="#ff9a2c"
            data-original="#ff9a2c"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m124.582031 442.117188c-40.3125-51.996094-36.917969-119.777344-36.863281-120.738282.640625-10.34375-7.207031-19.257812-17.566406-19.925781-10.363282-.664063-19.300782 7.199219-19.964844 17.5625-.238281 3.769531-4.925781 83.3125 44.558594 146.328125 12.820312-6.632812 22.558594-15 29.835937-23.226562zm0 0"
            fill="#ff7f00"
            data-original="#ff7f00"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m80.71875 373.207031c-11.277344 22.097657-9.328125 45.671875 4.351562 52.652344 13.679688 6.980469 33.910157-5.273437 45.1875-27.375 11.277344-22.097656 9.328126-45.671875-4.351562-52.652344s-33.914062 5.277344-45.1875 27.375zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m72.417969 375.867188c-22.101563-11.277344-45.671875-9.328126-52.652344 4.351562s5.273437 33.910156 27.371094 45.1875c22.097656 11.277344 45.671875 9.328125 52.652343-4.351562 6.980469-13.683594-5.273437-33.914063-27.371093-45.1875zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m72.449219 323.3125c-14.222657 20.328125-15.550781 43.949219-2.964844 52.753906 12.582031 8.804688 34.316406-.539062 48.539063-20.867187 14.226562-20.328125 15.554687-43.949219 2.96875-52.753907-12.585938-8.804687-34.316407.539063-48.542969 20.867188zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m63.875 324.847656c-20.328125-14.222656-43.949219-15.550781-52.753906-2.964844-8.804688 12.582032.539062 34.316407 20.867187 48.539063 20.332031 14.226563 43.949219 15.554687 52.753907 2.96875 8.804687-12.585937-.535157-34.320313-20.867188-48.542969zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m65.5 273.605469c-16.40625 18.613281-20.363281 41.933593-8.839844 52.089843 11.519532 10.15625 34.160156 3.300782 50.566406-15.3125 16.402344-18.613281 20.359376-41.933593 8.839844-52.089843-11.523437-10.15625-34.164062-3.300781-50.566406 15.3125zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m56.777344 274.152344c-18.609375-16.402344-41.933594-20.359375-52.085938-8.839844-10.15625 11.523438-3.300781 34.164062 15.3125 50.566406 18.613282 16.40625 41.933594 20.363282 52.089844 8.839844 10.152344-11.519531 3.296875-34.160156-15.316406-50.566406zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m69.171875 225.125c-18.015625 17.0625-24.054687 39.933594-13.492187 51.085938 10.5625 11.148437 33.726562 6.355468 51.738281-10.703126 18.015625-17.0625 24.054687-39.933593 13.492187-51.085937-10.5625-11.148437-33.726562-6.359375-51.738281 10.703125zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m60.421875 224.898438c-17.0625-18.011719-39.929687-24.050782-51.082031-13.488282s-6.359375 33.726563 10.703125 51.738282c17.0625 18.011718 39.933593 24.050781 51.085937 13.488281 11.148438-10.5625 6.355469-33.726563-10.707031-51.738281zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m72.804688 178.15625c-20.214844 14.386719-29.382813 36.191406-20.480469 48.707031 8.90625 12.515625 32.511719 10.996094 52.726562-3.386719 20.21875-14.386718 29.386719-36.191406 20.480469-48.707031s-32.511719-10.996093-52.726562 3.386719zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m64.203125 176.714844c-14.382813-20.214844-36.191406-29.382813-48.703125-20.476563-12.515625 8.902344-11 32.511719 3.386719 52.726563 14.382812 20.214844 36.191406 29.382812 48.707031 20.480468 12.511719-8.90625 10.996094-32.515624-3.390625-52.730468zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m411.34375 154.070312c4.125 24.464844 19.746094 42.226563 34.890625 39.675782 15.144531-2.554688 24.082031-24.457032 19.957031-48.921875-4.121094-24.464844-19.742187-42.226563-34.890625-39.675781-15.144531 2.550781-24.078125 24.457031-19.957031 48.921874zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m394.589844 489.308594c73.464844-64.566406 67.488281-166.027344 67.214844-170.292969-.664063-10.363281-9.601563-18.226563-19.964844-17.5625-10.359375.667969-18.203125 9.578125-17.5625 19.925781.058594 1.082032 4.378906 87.277344-54.6875 139.839844-7.753906 6.902344-8.449219 18.785156-1.546875 26.542969 4.464843 5.019531 15.34375 11.390625 26.546875 1.546875zm0 0"
            fill="#ff9a2c"
            data-original="#ff9a2c"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m387.410156 442.117188c40.316406-51.996094 36.921875-119.78125 36.867188-120.738282-.640625-10.347656 7.203125-19.257812 17.5625-19.925781 10.363281-.664063 19.300781 7.199219 19.964844 17.5625.242187 3.765625 4.929687 83.3125-44.554688 146.328125-12.824219-6.632812-22.5625-15-29.839844-23.226562zm0 0"
            fill="#ff7f00"
            data-original="#ff7f00"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m431.230469 373.203125c-11.277344-22.097656-31.507813-34.351563-45.1875-27.371094-13.679688 6.980469-15.628907 30.550781-4.351563 52.652344 11.273438 22.097656 31.507813 34.351563 45.1875 27.371094 13.679688-6.980469 15.628906-30.554688 4.351563-52.652344zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m439.578125 375.914062c-22.097656 11.277344-34.351563 31.507813-27.371094 45.1875 6.980469 13.679688 30.554688 15.628907 52.652344 4.351563 22.097656-11.273437 34.355469-31.507813 27.371094-45.1875-6.980469-13.679687-30.550781-15.628906-52.652344-4.351563zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m439.5625 323.34375c-14.222656-20.328125-35.957031-29.671875-48.542969-20.863281-12.582031 8.804687-11.253906 32.421875 2.96875 52.75 14.222657 20.332031 35.957031 29.671875 48.542969 20.867187 12.582031-8.804687 11.253906-32.421875-2.96875-52.753906zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m448.148438 324.824219c-20.328126 14.222656-29.671876 35.957031-20.863282 48.539062 8.804688 12.585938 32.421875 11.257813 52.75-2.964843 20.332032-14.222657 29.671875-35.957032 20.867188-48.542969-8.804688-12.585938-32.421875-11.257813-52.753906 2.96875zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m446.492188 273.59375c-16.40625-18.609375-39.042969-25.46875-50.566407-15.3125-11.523437 10.15625-7.5625 33.476562 8.839844 52.089844 16.40625 18.613281 39.042969 25.46875 50.566406 15.3125 11.523438-10.152344 7.566407-33.476563-8.839843-52.089844zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m455.203125 274.15625c-18.613281 16.40625-25.46875 39.046875-15.3125 50.566406 10.15625 11.523438 33.476563 7.566406 52.089844-8.839844 18.609375-16.402343 25.46875-39.042968 15.3125-50.566406-10.15625-11.519531-33.476563-7.5625-52.089844 8.839844zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m442.832031 225.097656c-18.015625-17.0625-41.179687-21.855468-51.742187-10.703125-10.5625 11.148438-4.523438 34.019531 13.492187 51.082031 18.011719 17.0625 41.175781 21.855469 51.738281 10.703126 10.5625-11.148438 4.523438-34.019532-13.488281-51.082032zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m451.535156 224.894531c-17.0625 18.011719-21.855468 41.175781-10.703125 51.738281 11.148438 10.5625 34.019531 4.523438 51.082031-13.488281s21.855469-41.175781 10.703126-51.738281c-11.148438-10.5625-34.019532-4.523438-51.082032 13.488281zm0 0"
            fill="#ffdaaa"
            data-original="#ffdaaa"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m439.207031 178.167969c-20.214843-14.386719-43.820312-15.902344-52.726562-3.386719s.261719 34.320312 20.480469 48.703125c20.214843 14.386719 43.820312 15.902344 52.726562 3.390625 8.902344-12.515625-.265625-34.324219-20.480469-48.707031zm0 0"
            fill="#ffaf50"
            data-original="#ffaf50"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m447.796875 176.695312c-14.386719 20.214844-15.902344 43.820313-3.386719 52.726563 12.511719 8.90625 34.320313-.261719 48.703125-20.480469 14.386719-20.214844 15.902344-43.820312 3.390625-52.726562-12.515625-8.902344-34.324218.265625-48.707031 20.480468zm0 0"
            fill="#ffc477"
            data-original="#ffc477"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m276.761719 409.554688c-12.539063 0-22.710938-10.167969-22.710938-22.710938v-128.828125l-33.117187 33.117187c-8.867188 8.871094-23.25 8.871094-32.117188 0-8.871094-8.867187-8.871094-23.246093 0-32.117187l71.886719-71.886719c6.496094-6.496094 16.269531-8.4375 24.75-4.921875 8.488281 3.515625 14.019531 11.796875 14.019531 20.980469v183.65625c0 12.542969-10.164062 22.710938-22.710937 22.710938zm0 0"
            fill="#ffde55"
            data-original="#ffde55"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m194.929688 8.058594 5.394531 9.859375c5.378906 9.835937 13.464843 17.925781 23.300781 23.300781l9.859375 5.394531c10.09375 5.519531 10.09375 20.011719 0 25.53125l-9.859375 5.394531c-9.835938 5.378907-17.921875 13.464844-23.300781 23.300782l-5.394531 9.863281c-5.519532 10.089844-20.011719 10.089844-25.53125 0l-5.394532-9.863281c-5.378906-9.835938-13.464844-17.921875-23.300781-23.300782l-9.859375-5.394531c-10.089844-5.519531-10.089844-20.011719 0-25.53125l9.859375-5.394531c9.835937-5.378906 17.925781-13.464844 23.300781-23.300781l5.394532-9.859375c5.519531-10.089844 20.011718-10.089844 25.53125 0zm0 0"
            fill="#abebf4"
            data-original="#abebf4"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="m347.835938 86.546875 3.40625 6.230469c3.398437 6.214844 8.507812 11.324218 14.722656 14.722656l6.230468 3.410156c6.378907 3.484375 6.378907 12.644532 0 16.132813l-6.230468 3.40625c-6.214844 3.402343-11.324219 8.511719-14.722656 14.726562l-3.40625 6.230469c-3.488282 6.375-12.648438 6.375-16.136719 0l-3.40625-6.230469c-3.398438-6.214843-8.507813-11.328125-14.722657-14.726562l-6.230468-3.40625c-6.378906-3.488281-6.378906-12.648438 0-16.132813l6.230468-3.410156c6.214844-3.398438 11.324219-8.507812 14.722657-14.722656l3.40625-6.230469c3.488281-6.378906 12.648437-6.378906 16.136719 0zm0 0"
            fill="#ccf8ff"
            data-original="#ccf8ff"
          />
        </g>
      </svg>
    </Box>
  );
}
