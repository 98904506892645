import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// material
import { Container, Alert, AlertTitle, Stack } from '@material-ui/core';
// components
import MButton from '../components/@material-extend/MButton';
// hooks
import useAuth from '../hooks/useAuth';

// ? -------------------------- Auth Guard Component -----------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, signin } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const isForceChangePassword = localStorage.getItem('isForceChangePassword');

  useEffect(() => {
    //   console.log('isForceChangePassword', isForceChangePassword);
    //   console.log('pathname', pathname);
    if (!isAuthenticated) {
      signin();
    }
    // else if (isForceChangePassword === 'True' && pathname !== '/force-change-assword') {
    //   window.location.replace('/force-change-assword');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isForceChangePassword === 'T' && pathname !== '/force-change-assword') {
    window.location.replace('/force-change-assword');
  }

  if (pathname !== requestedLocation) {
    setRequestedLocation(pathname);
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>UnAuthenticated</AlertTitle>
          Please signin before use this page.
        </Alert>
        <Stack sx={{ mt: 2 }} direction="row" justifyContent="center">
          <MButton onClick={signin} variant="contained" color="primary">
            Signin
          </MButton>
        </Stack>
      </Container>
    );
  }

  return children;
}
