import PropTypes from 'prop-types';
import { createContext, useReducer, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// materials
import { Typography, Alert, AlertTitle } from '@material-ui/core';
// OpenID-Client
import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
// moment
import moment from 'moment';
// Setting
import { openIdConfig } from '../config';
// Hooks
import useIsMountedRef from '../hooks/useIsMountedRef';

// -----------------------------------------------------------------------------
Log.logger = console;
Log.level = Log.INFO;

const SCREEN_DURATION_TIMEOUT = 30;
const SCREEN_ACTIVE_SCREEN_KEY = 'sft-active-screen';

const eventsCallbackContext = {
  onSignOut: null,
  onUserLoaded: null,
  onRenewError: null,
  onRenewAccessToken: null
};

export const userManager = new UserManager({
  authority: openIdConfig.wellknow,
  client_id: openIdConfig.clientId,
  scope: openIdConfig.scope,
  redirect_uri: openIdConfig.redirectUri,
  popup_redirect_uri: openIdConfig.popupRedirectUri,
  silent_redirect_uri: openIdConfig.silentRedirectUri,
  response_type: openIdConfig.responseType,
  post_logout_redirect_uri: openIdConfig.logoutRedirectUri,
  revokeAccessTokenOnSignout: true,
  monitorSession: false,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 300,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  popupWindowTarget: 'signin_popup'
});

// -----------------------------------------------------------------------------

const initState = {
  isInitialized: false,
  isAuthenticated: false,
  isAuthenticating: false,
  error: null,
  token: null,
  user: null,
  role: null,
  isForceChangePassword: null,
  team: null,
  type: 'identity',
  roleActivity: ''
};

export const OpenIdContext = createContext({
  ...initState,
  logout: null,
  getUserInfo: null,
  startSignIn: null
});

const stateHandler = {
  login: (state, { token, user, role, team, isForceChangePassword, roleActivity }) => ({
    ...state,
    isInitialized: true,
    isAuthenticated: true,
    token,
    user,
    role,
    team,
    isForceChangePassword,
    isForce: state.isForce === null ? isForceChangePassword === 'True' : state.isForce,
    error: null,
    roleActivity
  }),
  sessionExpired: () => ({
    ...initState,
    isInitialized: true,
    isAuthenticated: false
  }),
  authenticatedFailed: (state, { payload }) => ({
    ...state,
    isInitialized: true,
    isAuthenticated: false,
    error: payload
  }),
  setError: (state, { payload }) => ({
    ...state,
    error: payload
  }),
  initialized: (state) => ({
    ...state,
    isInitialized: true
  }),
  logout: () => ({
    ...initState
  })
};

const reducer = (state = initState, action) =>
  stateHandler[action.type] ? stateHandler[action.type](state, action) : state;

// -----------------------------------------------------------------------------

OpenIdProvider.propTypes = {
  children: PropTypes.node
};

export function OpenIdProvider({ children }) {
  // ? ---------------------------------- Hooks ------------------------------------
  const isMountedRef = useIsMountedRef();
  const activeScreenTime = useRef({
    ignore: true
  });
  const logoutState = useRef(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/auth') && !location.pathname.startsWith('/logout-front-channel')) {
      silentSignin();
    } else {
      dispatch({ type: 'initialized' });
    }

    async function silentSignin() {
      try {
        window.localStorage.setItem(SCREEN_ACTIVE_SCREEN_KEY, moment().format());

        userManager.events.addUserLoaded(onUserLoaded);
        userManager.events.addAccessTokenExpired(onTokenExpired);

        const user = await userManager.getUser();
        if (user && !user.expired) {
          onUserLoaded(user);
        } else {
          await userManager.signinSilent();
        }

        userManager.startSilentRenew();

        // Set Session Active Screen
        activeScreenTime.current.ignore = false;
      } catch (ex) {
        if (ex.error === 'login_required') {
          // user not logged in at idp, open popup dialog
          signin();
        } else {
          dispatch({
            type: 'authenticatedFailed',
            payload: (ex.data && ex.data.message) || ex.error || ex.message || 'Authenticated Failed'
          });
        }
      }
    }

    function onUserLoaded(user) {
      if (isMountedRef.current) {
        dispatch({
          type: 'login',
          token: user.access_token,
          user,
          role: user.profile.role,
          team: user.profile.team,
          isForceChangePassword: user.profile.isForceChangePassword,
          roleActivity: user.profile.role_activity
        });
        localStorage.setItem('roleActivity', user.profile.role_activity);
        const isForceChangePassword = localStorage.getItem('isForceChangePassword');
        if (isForceChangePassword === undefined || isForceChangePassword === null) {
          localStorage.setItem('isForceChangePassword', user.profile.isForceChangePassword === 'True' ? 'T' : 'F');
        }
      }
    }

    function onTokenExpired() {
      if (isMountedRef.current) {
        dispatch({ type: 'sessionExpired' });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Register Check Active Screen
  useEffect(() => {
    const onActiveScreen = () => {
      window.localStorage.setItem(SCREEN_ACTIVE_SCREEN_KEY, moment().format());
    };

    window.addEventListener('mousemove', onActiveScreen);
    window.addEventListener('mousedown', onActiveScreen);
    window.addEventListener('keypress', onActiveScreen);
    window.addEventListener('touchmove', onActiveScreen);
    window.addEventListener('scroll', onActiveScreen);

    const intervalId = setInterval(async () => {
      if (activeScreenTime.current.ignore || !isMountedRef.current) {
        return;
      }

      let isExpired = false;
      // Get From LocalStorage
      const lastActiveScreenValue = window.localStorage.getItem(SCREEN_ACTIVE_SCREEN_KEY);

      if (!lastActiveScreenValue) {
        isExpired = true;
      } else {
        // Check Expired
        const lastActiveScreenTime = moment(lastActiveScreenValue);
        if (
          moment()
            .add(SCREEN_DURATION_TIMEOUT * -1, 'minute')
            .isSameOrAfter(lastActiveScreenTime)
        ) {
          isExpired = true;
        }
      }

      if (isExpired) {
        activeScreenTime.current.ignore = true;
        const user = await userManager.getUser();

        // Check is authenticated or not
        if (user != null) {
          userManager.signoutRedirect();
        }
      }
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
      window.removeEventListener('mousemove', onActiveScreen);
      window.removeEventListener('mousedown', onActiveScreen);
      window.removeEventListener('keypress', onActiveScreen);
      window.removeEventListener('touchmove', onActiveScreen);
      window.removeEventListener('scroll', onActiveScreen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    localStorage.removeItem('isForceChangePassword');
    if (!logoutState.current) {
      // Prevent user from double click
      logoutState.current = true;
      // Set Session Active Screen
      activeScreenTime.current.ignore = true;

      userManager.signoutRedirect();
    }
  };

  const signin = async () => {
    // Set Session Active Screen
    activeScreenTime.current.ignore = true;

    try {
      await userManager.signinPopup();
      userManager.startSilentRenew();
      // Set Session Active Screen
      activeScreenTime.current.ignore = false;
    } catch (ex) {
      dispatch({
        type: 'authenticatedFailed',
        payload: (ex.data && ex.data.message) || ex.error || ex.message || 'Authenticated Failed'
      });
    }
  };

  return (
    <OpenIdContext.Provider
      value={{
        ...state,
        signin,
        logout
      }}
    >
      {state.error && (
        <Alert sx={{ mt: 10 }} severity="error">
          <AlertTitle>Authentication Error</AlertTitle>
          {state.error}
        </Alert>
      )}
      {children}
    </OpenIdContext.Provider>
  );
}
