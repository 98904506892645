export const openIdConfig = {
  wellknow: process.env.REACT_APP_OPENID_WELLKNOW_URL,
  clientId: process.env.REACT_APP_OPENID_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OPENID_REDIRECT_URI,
  silentRedirectUri: process.env.REACT_APP_OPENID_SLIENT_REDIRECT_URI,
  popupRedirectUri: process.env.REACT_APP_OPENID_POPUP_REDIRECT_URI,
  scope: process.env.REACT_APP_OPENID_COPE,
  responseType: process.env.REACT_APP_OPENID_RESPONSE_TYPE,
  logoutRedirectUri: process.env.REACT_APP_OPENID_LOGOUT_REDIRECT_URI
};
export const azureAdConfig = {
  authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
  clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_POST_LOGOUT_REDIRECT_URI
};
export const appConfig = {
  uri: process.env.REACT_APP_WEB_API_URI,
  identityUri: process.env.REACT_APP_WEB_IDENTITY_URI
};
