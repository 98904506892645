// ----------------------------------------------------------------------

import { clone } from 'lodash';

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REPORT_BENEFIT_COST = '/report-benefit-cost';
const ROOTS_REPORT_FAIR_VALUE = '/report-fair-value';
const ROOTS_REPORT_PLANTAION_COST = '/report-plantation-cost';
const ROOTS_DASHBOARD_BI = '/bi';
const ROOTS_NEWREGIST = '/new-regist';
const ROOTS_PLANTATION = '/plantation';
const ROOTS_INTERFACE = '/interface';
const ROOTS_MASTER = '/master';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  reportPlantationCost: {
    root: path(ROOTS_REPORT_PLANTAION_COST, '/'),
    byActivity: path(ROOTS_REPORT_PLANTAION_COST, '/by-activity'),
    byAssignment: path(ROOTS_REPORT_PLANTAION_COST, '/by-assignment')
  },
  reportBenefitCost: ROOTS_REPORT_BENEFIT_COST,
  reportFairValue: ROOTS_REPORT_FAIR_VALUE,
  dashboard: ROOTS_DASHBOARD_BI,
  newRegist: {
    root: path(ROOTS_NEWREGIST, '/'),
    statusTracking: path(ROOTS_NEWREGIST, '/status-tracking'),
    powerApp: path(ROOTS_NEWREGIST, '/power-app')
  },
  plantation: {
    root: path(ROOTS_PLANTATION, '/'),
    createPlantation: path(ROOTS_PLANTATION, '/create-plantation'),
    createUnplan: path(ROOTS_PLANTATION, '/create-unplan'),
    rollingPlan: path(ROOTS_PLANTATION, '/rolling-plan'),
    calendar: path(ROOTS_PLANTATION, '/calendar'),
    unplan: path(ROOTS_PLANTATION, '/unplan')
  },
  interface: {
    root: path(ROOTS_INTERFACE, '/'),
    createPO: path(ROOTS_INTERFACE, '/create-po'),
    createNonPO: path(ROOTS_INTERFACE, '/create-non-po'),
    po: path(ROOTS_INTERFACE, '/po'),
    nonPO: path(ROOTS_INTERFACE, '/non-po'),
    sapGI: path(ROOTS_INTERFACE, '/sap-gi'),
    createSapGI: path(ROOTS_INTERFACE, '/create-sap-gi'),
    payment: path(ROOTS_INTERFACE, '/payment'),
    mapping99999: path(ROOTS_INTERFACE, '/mapping-99999')
  },
  master: {
    root: path(ROOTS_MASTER, '/'),
    activity: path(ROOTS_MASTER, '/activity'),
    clone: path(ROOTS_MASTER, '/clone'),
    transportation: path(ROOTS_MASTER, '/transportation'),
    user: path(ROOTS_MASTER, '/user')
  }
  // management: {
  //   root: path(ROOTS_DASHBOARD, '/management'),
  //   statusTracking: path(ROOTS_DASHBOARD, '/management/statustracking'),
  //   samplePage: path(ROOTS_DASHBOARD, '/management/samplePage'),
  //   rental: path(ROOTS_DASHBOARD, '/management/rental/:id'),
  //   subrental: path(ROOTS_DASHBOARD, '/management/subrental/:id'),
  //   subrentalphysical: path(ROOTS_DASHBOARD, '/management/subrentalphysical/:id'),
  //   vip: path(ROOTS_DASHBOARD, '/management/vip/:id'),
  //   subvip: path(ROOTS_DASHBOARD, '/management/subvip/:id'),
  //   mou: path(ROOTS_DASHBOARD, '/management/mou/:id'),
  //   assumptionandexpense: path(ROOTS_DASHBOARD, '/management/assumptionandexpense/:id')
  // }
};
