import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
// components
import LoadingScreen from './LoadingScreenType1';

import Logo2 from '../assets/loading_tree.gif';

// ? -------------------------------- Context ----------------------------------

const LoadingScreenContext = createContext({
  setOpenLoading: null
});

export const useLoadingScreen = () => useContext(LoadingScreenContext);

// ? -------------------------------- Component ----------------------------------

LoadingScreenProvider.propTypes = {
  children: PropTypes.node
};

export default function LoadingScreenProvider({ children }) {
  const [openLoading, setOpenLoading] = useState(false);

  return (
    <LoadingScreenContext.Provider value={{ setOpenLoading }}>
      {children}
      {openLoading && (
        // <div
        //   style={{
        //     position: 'fixed',
        //     width: '100%',
        //     top: 0,
        //     backgroundColor: '#9f9f9f54',
        //     zIndex: 99999
        //   }}
        // >
        //   <img src={Logo2} alt="loading..." />
        // </div>
        <LoadingScreen
          style={{
            position: 'fixed',
            width: '100%',
            top: 0,
            backgroundColor: '#9f9f9f54',
            zIndex: 99999
          }}
        />
      )}
    </LoadingScreenContext.Provider>
  );
}
