import { useContext } from 'react';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
// icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import LazySize from '../../components/LazySize';
//
import { MHidden, MIconButton } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';

import { OpenIdContext } from '../../contexts/OpenIdContext';
import { uniqueArray } from '../../components/helper';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: open && DRAWER_WIDTH
  },
  width: 0,
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen
  })
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { role, roleActivity } = useContext(OpenIdContext);
  let showPages = [];
  if (roleActivity) {
    try {
      const roleAct = JSON.parse(roleActivity);
      const authX = roleAct.map((x) => x.Page);
      showPages = sidebarConfig
        .filter((x) => authX.includes(x.subheader))
        .map((x) => ({
          ...x,
          items: x.items
            .filter((y) => authX.includes(y.title))
            .map((y) => ({
              ...y,
              children: y.children && y.children.filter((z) => authX.includes(z.title)).map((z) => z)
            }))
        }));
    } catch {
      showPages = sidebarConfig;
    }
  }

  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
      <Box sx={{ px: 2.5, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>
          <LazySize alt="logo_scg" sx={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/static/brand/logo_scg.png`} />
        </Box>
        <MIconButton onClick={onCloseSidebar}>
          <ArrowBackIosIcon />
        </MIconButton>
      </Box>

      <NavSection navConfig={showPages} />
    </Scrollbar>
  );

  return (
    <RootStyle open={isOpenSidebar}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={isOpenSidebar}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
