import Moment from 'moment';
import MChip from './@material-extend/MChip';

export const GuidEmpty = '00000000-0000-0000-0000-000000000000';

export function textRequire() {
  return <span style={{ color: 'red' }}>* </span>;
}

export function isGuidEmpty(val) {
  return val === GuidEmpty;
}

export function getDataFromAutoComplete(val) {
  return val ? (val.length > 0 ? val.map((x) => x.value).join(',') : val.value) || null : null;
}

export function getDataFromAutoCompleteBool(val) {
  return val ? val.value === 'true' : null;
}

export function setTotalRecordsTab(data, label) {
  let cnt = 0;
  if (data && data.length) {
    data.forEach((x) => {
      if (x.children !== null && x.children !== undefined) {
        cnt += x.children.length;
      } else {
        cnt += 1;
      }
    });
  }
  return (
    <span>
      {label}
      &nbsp;&nbsp;&nbsp;
      {data && data.length > 0 ? <MChip label={`Result ${toFixed(cnt, 0)}  Records`} color="info" /> : null}
    </span>
  );
}

export function setTotalRecords(data) {
  let cnt = 0;
  if (data && data.length) {
    data.forEach((x) => {
      if (x.children !== null && x.children !== undefined) {
        cnt += x.children.length;
      } else {
        cnt += 1;
      }
    });
  }
  return (
    <span>
      {'  '}
      <MChip label="Data Max 1,000 Records" color="error" />
      {'  '}
      {data && data.length > 0 ? <MChip label={`Result ${toFixed(cnt, 0)}  Records`} color="info" /> : null}
    </span>
  );
}

export function setTotalRows(data, select) {
  const selected = select && select.length;
  const total = data && data.length;
  return (
    <span>
      {'  '}
      <MChip label={`Total ${toFixed(total, 0)}`} color="error" />
      {'  '}
      {select !== null && <MChip label={`Selected ${toFixed(selected, 0)}`} color="info" />}
    </span>
  );
}

export function setTotalMapping9999(data, mappingTransId) {
  let cnt = 0;
  let total = 0;
  if (data && data.length) {
    data.forEach((x) => {
      if (x.children !== null && x.children !== undefined) {
        cnt += x.children.filter((z) => z.mappingTransId === mappingTransId && z.status === 'Confirmed').length;
        total += x.children.filter((z) => z.mappingTransId === null || z.mappingTransId === mappingTransId).length;
      }
    });
  } else {
    return '';
  }
  return (
    data &&
    data.length && (
      <span>
        {'  '}
        <MChip label={`Total ${toFixed(total, 0)}`} color="error" />
        {'  '}
        <MChip label={`Confirm ${toFixed(cnt, 0)}`} color="success2" />
        {'  '}
        <MChip label={`Remaining ${toFixed(total - cnt, 0)}`} color="info" />
      </span>
    )
  );
}

export function setIsActive(status, label) {
  if (status) return <MChip label={label} color="success2" />;
  else return <MChip label={label} color="error" />;
}

export function setStatusMatch9999(row, label) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['confirmed'].indexOf(statusLower) >= 0) color = 'info';
  return <MChip key={row.id} label={label} color={color} />;
}

export function setStatusMapping9999(row, label) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['wait for approve'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['wait for mapping'].indexOf(statusLower) >= 0) color = 'info';
  else if (['approved'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['rejected'].indexOf(statusLower) >= 0) color = 'error';
  return (
    <MChip
      key={row.id}
      label={label}
      color={color}
      onClick={() => window.open(`/interface/mapping-99999/${row.id}`, '_blank')}
    />
  );
}

export function setStatusSAPGI(row, label) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['failed'].indexOf(statusLower) >= 0) color = 'error';

  return (
    <MChip
      key={row.id}
      label={label}
      color={color}
      onClick={() => window.open(`/interface/create-sap-gi/${row.id}`, '_blank')}
    />
  );
}

export function setStatusPO(row, label, click = true) {
  const statusLower = (row.poStatus || '').toLowerCase();
  let color = 'default';
  if (['pending approval'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['pr approved'].indexOf(statusLower) >= 0) color = 'info';
  else if (['po created'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['failed', 'rejected', 'deleted', 'sap error'].indexOf(statusLower) >= 0) color = 'error';

  return (
    <MChip
      key={row.id}
      label={label}
      color={color}
      onClick={() => {
        if (click) window.open(`/interface/create-po/${row.id}`, '_blank');
      }}
    />
  );
}

export function setStatusNonPO(row, label) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'warning';
  if (['draft'].indexOf(statusLower) >= 0) color = 'default';
  // else if (['pr approved'].indexOf(statusLower) >= 0) color = 'info';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['rejected', 'hold', 'cancelled'].indexOf(statusLower) >= 0) color = 'error';

  return (
    <MChip
      key={row.id}
      label={label}
      color={color}
      onClick={() => window.open(`/interface/non-po/${row.id}`, '_blank')}
    />
  );
}
export function setStatusPlantaion2(label) {
  const statusLower = label.toLowerCase();
  let color = 'default';
  if (['operating'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['close'].indexOf(statusLower) >= 0) color = 'default';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'error';
  return <MChip key={label} label={label} color={color} />;
}
export function setStatusPlantaion(row, label, type) {
  const statusLower = (row.statusPlantation || '').toLowerCase();
  let color = 'default';
  if (['operating'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['close'].indexOf(statusLower) >= 0) color = 'default';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'error';
  return label !== null ? (
    <MChip
      key={row.id}
      label={label}
      color={color}
      onClick={() => {
        if (type === 'Rental') {
          window.open(`/plantation/plantationrental/${row.id}`, '_blank');
        } else if (type === 'MOU') window.open(`/plantation/plantationmou/${row.id}`, '_blank');
        else if (type === 'VIP') window.open(`/plantation/plantationvip/${row.id}`, '_blank');
      }}
    />
  ) : null;
}

export function setStatusTracking(row, label, type) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['verify cost'].indexOf(statusLower) >= 0) color = 'info';
  else if (['verify feas.'].indexOf(statusLower) >= 0) color = 'info2';
  else if (['final negotiate'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['contract signed'].indexOf(statusLower) >= 0) color = 'warning2';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'error';
  return row.status ? (
    <MChip
      key={row.registNo}
      label={label}
      color={color}
      onClick={() => {
        if (type === 'Rental') window.open(`/new-regist/rental/${row.id}`, '_blank');
        else if (type === 'MOU') window.open(`/new-regist/mou/${row.id}`, '_blank');
        else if (type === 'VIP') window.open(`/new-regist/vip/${row.id}`, '_blank');
      }}
    />
  ) : null;
}

export function setStatusCalendar(row, label, type) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['plan'].indexOf(statusLower) >= 0) color = '#1890FF';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'rgb(196, 196, 196)';
  else if (['on progress'].indexOf(statusLower) >= 0) color = '#FFC107';
  else if (['done'].indexOf(statusLower) >= 0) color = '#007b55';
  else if (['delayed'].indexOf(statusLower) >= 0) color = '#f32a3d';
  // console.log('color', color, row.status);
  return row.status ? (
    <MChip
      key={row.registNo}
      label={label}
      style={{
        backgroundColor: color,
        color: '#fff'
      }}
    />
  ) : null;
}

export function setCountTransaction(nums) {
  return <MChip label={`${nums} Transaction`} color="info" style={{ fontWeight: '900' }} />;
}

export function setPlantaionByRegistID(row, label, type) {
  const statusLower = (row.status || '').toLowerCase();
  let color = 'default';
  if (['verify cost', 'verify feas.'].indexOf(statusLower) >= 0) color = 'info';
  else if (['final negotiate', 'contract signed'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'error';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  return row.status ? (
    <MChip
      key={row.registNo}
      label={label}
      color={color}
      onClick={() => {
        if (type === 'Rental') window.open(`/new-regist/rental/${row.newRegistId}`, '_blank');
        else if (type === 'MOU') window.open(`/new-regist/mou/${row.newRegistId}`, '_blank');
        else if (type === 'VIP') window.open(`/new-regist/vip/${row.newRegistId}`, '_blank');
      }}
    />
  ) : null;
}

export function setPlantaionByPlantationID(id, label, type, status) {
  const statusLower = (status || '').toLowerCase();
  let color = 'default';
  if (['not created'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['cancel'].indexOf(statusLower) >= 0) color = 'error';
  else if (['completed'].indexOf(statusLower) >= 0) color = 'success2';
  return label ? (
    <MChip
      key={id}
      label={label}
      color={color}
      onClick={() => {
        if (type === 'Rental') {
          window.open(`/plantation/plantationrental/${id}`, '_blank');
        } else if (type === 'MOU') window.open(`/plantation/plantationmou/${id}`, '_blank');
        else if (type === 'VIP') window.open(`/plantation/plantationvip/${id}`, '_blank');
      }}
    />
  ) : null;
}
export function setStatusRollingPlan(status) {
  const statusLower = (status || '').toLowerCase();
  let color = 'default';
  if (['plan'].indexOf(statusLower) >= 0) color = '#007b55';
  else if (['start'].indexOf(statusLower) >= 0) color = '#f3ac2a';
  else if (['on progress'].indexOf(statusLower) >= 0) color = '#2af3ce';
  else if (['done'].indexOf(statusLower) >= 0) color = '#ddf32a';
  else if (['delayed'].indexOf(statusLower) >= 0) color = '#f32a3d';
  else if (['cancel'].indexOf(statusLower) >= 0) color = '#FF4842';
  return status ? (
    <MChip
      key={status}
      label={status}
      style={{
        backgroundColor: color,
        color: '#fff'
      }}
    />
  ) : null;
}

export function setEmail(txt) {
  if (txt === null || txt === '') return '';

  return txt.split(',').map((o) => (
    <>
      {o}
      <br />
    </>
  ));
}

export function setUnplanStatus(id, status) {
  const statusLower = (status || '').toLowerCase();
  let color = 'default';
  if (['draft'].indexOf(statusLower) >= 0) color = 'warning';
  else if (['wait for approve', 'wait for approve (my task)'].indexOf(statusLower) >= 0) color = 'info';
  else if (['approved'].indexOf(statusLower) >= 0) color = 'success2';
  else if (['rejected'].indexOf(statusLower) >= 0) color = 'error';
  else if (['deleted'].indexOf(statusLower) >= 0) color = 'default';
  return status ? <MChip key={id} label={status} color={color} /> : null;
}

export function toNumber(number, float = 2) {
  if (number >= 0 || !number) return toFixed(number, float);
  number = Math.abs(number);
  return `(${toFixed(number, float)})`;
}

export function toRoundTen(num) {
  return Math.round((num + Number.EPSILON) / 10) * 10;
}

export function toFixed(number, digit = 3, noComma = false, noFixZero = false) {
  if (number === null || number === undefined) return null;
  if (isNaN(number)) number = 0;
  // if (number === 0) return '0';
  let rounded = 0;

  if (noFixZero) {
    const s = number.toString().split('.');
    if (s[1] && s[1].length > digit) {
      rounded = toFixedNumber(number, digit);
    } else {
      rounded = Number(number);
    }
  } else rounded = toFixedNumber(number, digit);

  let parts = rounded.toFixed(digit).split('.');
  if (noFixZero) parts = rounded.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (noComma) return parts.join('.').replace(/,/g, '');
  return parts.join('.');
}

export function compareString(a, b) {
  if (a === null || a === undefined) a = '';
  if (b === null || b === undefined) b = '';
  return a.toString().localeCompare(b.toString());
}

export function compareDate(a, b) {
  if (!a && !b) return 0;
  else if (!a) return 1;
  else if (!b) return -1;
  else {
    const ma = Moment(a);
    const mb = Moment(b);

    return ma.isBefore(mb) ? -1 : ma.isAfter(mb) ? 1 : 0;
  }
}

export function notNumber(a) {
  return a === null || a === undefined || a === '' || isNaN(a) || typeof a === 'boolean';
}

export function compareNumber(a, b) {
  let aNaN = false;
  let bNaN = false;
  if (notNumber(a)) aNaN = true;
  if (notNumber(b)) bNaN = true;

  if (aNaN && bNaN) return 0;
  else if (aNaN) return 1;
  else if (bNaN) return -1;
  else return Number(a) - Number(b);
}

export function toDateStr(date) {
  Moment.locale('en');
  if (!date) return null;
  return Moment(date).format('DD/MM/YYYY');
}

export function toDateTimeStr(date) {
  Moment.locale('en');
  if (!date) return null;
  return Moment(date).format('DD/MM/YYYY HH:mm');
}

export function DefaultGuid() {
  return '00000000-0000-0000-0000-000000000000';
}

export function toFixedNumber(number, float = 0) {
  if (float === null || float === '' || float === undefined) float = 0;
  if (isNaN(float)) float = 0;
  if (!(number || '').toString().replace(/,/g, '')) return 0;
  if (isNaN((number || '').toString().replace(/,/g, ''))) return 0;

  number = Number(number.toString().replace(/,/g, ''));
  const div = Math.pow(10, float);
  return Math.round(number * div) / div;
}

export function uniqueArray(arrArg) {
  return arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
}

export function uniqueList(list) {
  const dataArr = list.map((item) => [JSON.stringify(item), item]);
  const maparr = new Map(dataArr);
  const result = [...maparr.values()];
  return result;
}

export function getRoleActivity(page) {
  const roleActivity = localStorage.getItem('roleActivity');
  if (roleActivity) {
    try {
      const roleAct = JSON.parse(roleActivity);
      const authPage = roleAct.filter((x) => x.Page === page);
      if (authPage === null || authPage.length === 0) {
        window.location = '/404';
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

export function openPdf(data) {
  if (data.file && data.file.length > 0) {
    const binaryString = window.atob(data.file); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    const bytes2 = bytes.map((byte, i) => binaryString.charCodeAt(i));
    const newBlob = new Blob([bytes2], {
      type: 'application/pdf'
    });
    const fileURL = URL.createObjectURL(newBlob);
    window.open(fileURL);
  } else if (data.size > 0) {
    const newBlob = new Blob([data], {
      type: 'application/pdf'
    });
    const fileURL = URL.createObjectURL(newBlob);
    window.open(fileURL);
  }
}

export function downloadFile(data, fileName) {
  let newBlob;
  if (data.file && data.file.length > 0) {
    const binaryString = window.atob(data.file); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    const bytes2 = bytes.map((byte, i) => binaryString.charCodeAt(i));
    newBlob = new Blob([bytes2], {
      type: 'application/pdf'
    });
  } else if (data.size > 0) {
    newBlob = new Blob([data], {
      type: 'application/pdf'
    });
  }

  const url = URL.createObjectURL(newBlob);
  const a = window.document.createElement('a');
  a.download = fileName || 'pdf.pdf';
  a.style = 'display:none;';
  a.href = url;
  window.document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}

export function getRowKey(data, key, list = []) {
  if (data !== undefined && data !== null) {
    data.forEach((element) => {
      if (element.children && element.children !== null && element.children.length > 0) {
        list.push(element[key]);
        getRowKey(element.children, key, list);
      }
    });
  }

  return list;
}

export function getColorDiff(result) {
  let color = '#b0120a';
  if (result >= 10) color = '#558b2f';
  else if (result >= 0) color = '#01579b';
  else if (result >= -10) color = '#fbc02d';
  else color = '#b0120a';
  return color;
}
export function convertBase64ToFile(base64String, fileName) {
  // const arr = base64String.split(',');
  // const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(base64String);
  let n = bstr.length;
  const uint8Array = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  const file = new File([uint8Array], fileName);
  return file;
}
