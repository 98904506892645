// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import InsertDriveFile from '../../../node_modules/@material-ui/icons/InsertDriveFile';
import AssessmentTwoToneIcon from '../../../node_modules/@material-ui/icons/AssessmentTwoTone';
import SchemaTwoToneIcon from '../../../node_modules/@material-ui/icons/SchemaTwoTone';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getIcon2 = () => <InsertDriveFile />;

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  elements: getIcon('ic_elements'),
  map: getIcon('ic_map'),
  upload: getIcon('ic_upload'),
  InsertDriveFile: getIcon2(),
  reportBenefitCost: <AssessmentTwoToneIcon />,
  reportFairValue: <SchemaTwoToneIcon />
};

const sidebarConfig = [
  // Invoice Management Following Payment
  // ----------------------------------------------------------------------
  {
    subheader: 'Report & Dashboard',
    items: [
      {
        title: 'Report Plantation Cost',
        path: PATH_DASHBOARD.reportPlantationCost.root,
        icon: ICONS.elements,
        children: [
          {
            title: 'By Activity',
            path: PATH_DASHBOARD.reportPlantationCost.byActivity
          },
          {
            title: 'By Activity & Year',
            path: PATH_DASHBOARD.reportPlantationCost.byAssignment
          }
        ]
      },
      {
        title: 'Report Benefit Cost',
        path: PATH_DASHBOARD.reportBenefitCost,
        icon: ICONS.reportBenefitCost
      },
      {
        title: 'Report Fair Value',
        path: PATH_DASHBOARD.reportFairValue,
        icon: ICONS.reportFairValue
      },
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.dashboard,
        icon: ICONS.analytics
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'New Registration',
        path: PATH_DASHBOARD.newRegist.root,
        icon: ICONS.InsertDriveFile,
        children: [
          {
            title: 'Status Tracking',
            path: PATH_DASHBOARD.newRegist.statusTracking
          },
          {
            title: 'Power App',
            path: PATH_DASHBOARD.newRegist.powerApp
          }
        ]
      },
      {
        title: 'Plantation',
        path: PATH_DASHBOARD.plantation.root,
        icon: ICONS.map,
        children: [
          {
            title: 'Create Plantation',
            path: PATH_DASHBOARD.plantation.createPlantation
          },
          {
            title: 'Create Unplan',
            path: PATH_DASHBOARD.plantation.createUnplan
          },
          {
            title: 'Rolling Plan',
            path: PATH_DASHBOARD.plantation.rollingPlan
          },
          {
            title: 'Calendar',
            path: PATH_DASHBOARD.plantation.calendar
          }
        ]
      },
      {
        title: 'Interface',
        path: PATH_DASHBOARD.interface.root,
        icon: ICONS.upload,
        children: [
          {
            title: 'Create PO',
            path: PATH_DASHBOARD.interface.po
          },
          {
            title: 'Create Non-PO',
            path: PATH_DASHBOARD.interface.createNonPO
          },
          {
            title: 'SAP-GI',
            path: PATH_DASHBOARD.interface.sapGI
          },
          // {
          //   title: 'Payment',
          //   path: PATH_DASHBOARD.interface.payment
          // },
          {
            title: 'Mapping 99999',
            path: PATH_DASHBOARD.interface.mapping99999
          }
        ]
      },
      {
        title: 'Master',
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.components,
        children: [
          {
            title: 'Activity',
            path: PATH_DASHBOARD.master.activity
          },
          {
            title: 'Clone',
            path: PATH_DASHBOARD.master.clone
          },
          {
            title: 'Transportation',
            path: PATH_DASHBOARD.master.transportation
          },
          {
            title: 'User',
            path: PATH_DASHBOARD.master.user
          }
        ]
      }
    ]
  }
];

export default sidebarConfig;
